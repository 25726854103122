export const SharedConfig = {
  TOKEN_RENEW_HEADER: 'Authorization-renew',
  AUTH_HEADER: 'Authorization',
  ORGANIZATION_HEADER: 'Organization',
  FORM_HEADER: 'Timekit-Form',

  API_PREFIX: 'api',
  APP_NAME: 'TimeKit',
  TIMEZONE: 3,
}
